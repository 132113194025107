@charset "UTF-8";

@import "breakpoint";

// !!! check path
@import "../../../drupal/7-prod/sites/all/themes/material_base/sass/reset";
@import "../../../drupal/7-prod/sites/all/themes/material_base/sass/color-palette";
@import "variables";
@import "../../../drupal/7-prod/sites/all/themes/material_base/sass/variables";
@import "../../../drupal/7-prod/sites/all/themes/material_base/sass/modules";

// put your styles here
// you can @extend anything from base theme

.visible-480 { display: none; }
@include breakpoint(480px) { 
  .visible-480 { display: block; }
  .navbar-item.visible-480 { display: inline-block; }
}

@include breakpoint($lg + 24px) {
  .navbar-content, .footer-content { margin-left: auto; margin-right: auto; max-width: $lg; padding: 0 $gutter / 2; }
}

.fs-24 { font-size: 24px; }

.photoswipe { position: relative;
  &:after { @extend .material-icons; content: "zoom_in"; position: absolute; right: 12px; top: 12px; color: rgba($color-text-light, .75); }
}

.header-content { margin: 48px auto 64px;
  h1, h2, .h1, h2 { font-size: 28px; line-height: 36px; }
  .header-working { color: #00c853; display: flex; align-items: center; opacity: 0;
    &.open-now { opacity: 1; }
    .working-icon { width: 8px; height: 8px; border-radius: 50%; background: #00c853; margin-right: 8px; }
  }
  @include breakpoint($xs) { 
    & { margin: 96px auto 96px; }
    h1, h2, .h1, h2 { font-size: 32px; line-height: 44px; }
  }
  @include breakpoint($sm) { 
    & { margin: 160px auto 160px; }
    h1, h2, .h1, h2 { font-size: 36px; line-height: 52px; }
  }
}

.header-bg-image.navbar-fixed #header-bg {
  background-image: url('../img/header-bg-grid.png'), linear-gradient(to bottom, rgba(0,0,0,0.4) 0%,rgba(0,0,0,0) 64px), url('../img/header-bg.jpg') !important;
  background-position: top left, top left, center center !important;
  background-size: auto, auto, cover !important;
}

.navbar-logo-icon, .navbar-phone-icon { margin: 0 -12px; }

.navbar-phone { height: 48px; font-size: 16px; margin: 0 -12px; padding: 12px 12px 12px 12px; font-size: 16px; line-height: 24px; 
  a { color: #FFF; font-weight: 500; }
}

@include breakpoint($lg) {
  .navbar-menu .menu a { font-size: 16px; }
  .navbar-phone a { font-size: 20px; line-height: 24px; }
  .navbar-item .navbar-icon { width: 28px; height: 28px; margin: 10px; font-size: 28px; }
}

.block-features-1 { background: color($grey, 200); padding-bottom: 96px;
  .item { margin-top: 32px; margin-bottom: 32px; }
  .block-title { color: $color-primary; }
  .material-icons { font-size: 64px; color: color($orange, 800); }
  .description { max-width: 240px; margin: 24px auto 0 auto; font-size: 18px; }
  @include breakpoint($sm) { 
    .item { margin-top: 48px; margin-bottom: 48px; }
  }
  @include breakpoint($md) { 
    .item { margin-top: 64px; margin-bottom: 64px; }
  }
}

.block-features-1-addition { background: color($orange, 400); position: relative; padding-top: 64px; 
  .features-1-addition { margin-top: -120px; font-size: 18px; position: relative; z-index: 3;
    .text { max-width: 520px; }
  } 
}

.block-features-2 { 
  .block-bg { background: color($orange, 400); position: relative; overflow: hidden;
    &:after { position: absolute; top: 50%; color: $color-text-light; font-size: 192px; margin-top: -96px; right: -48px; opacity: 0.2; z-index: 1; } 
  }
  .container { position: relative; z-index: 2; }
  .feature-item { font-size: 18px; margin-bottom: 24px; padding-left: 48px; position: relative;
    &:before { font-size: 32px; position: absolute; left: 0; top: 12px; }
  }
  @include breakpoint($xs) { 
    .feature-item { font-size: 18px; margin-bottom: 24px; padding-left: 128px; padding-right: 64px; position: relative;
      &:before { left: 64px; }
    }
  }
}

.product-list { position: relative; overflow: hidden;
  &:before { position: absolute; top: 25%; font-size: 192px; left: 64px; opacity: 0.1; z-index: 1; }
  .block-bg:after { position: absolute; bottom: 22%; font-size: 192px; right: 64px; opacity: 0.1; z-index: 1; }
  .container { position: relative; z-index: 2; }
  .block-title { color: $color-primary; }
  .product-list-card { margin: 24px 0;
    .title { background: linear-gradient(to bottom, rgba(0,0,0, 0.4) 0%, rgba(0,0,0, 0) 46px, rgba(0,0,0, 0) 100%); color: $color-text-light; }
  }
  .price { line-height: 22px; font-size: 16px; font-weight: 500; padding: 0 0 0 16px; margin: 8px;
    .price-description { display: block; font-size: 10px; font-weight: normal; line-height: 14px; }
  }
  .price.light, .number.light { color: $color-text-light; }
  .btn { background: rgba(#FFF,0.35); }
  .number { line-height: 20px; font-size: 16px; font-weight: 400; padding: 8px 0; margin: 8px; }
  .card-media .title { padding-right: 48px; }
  @include breakpoint($xs) { 
    .product-list-card { margin: 24px 12px; }
  }
}

.card-bg-blue-800 { background: color($blue, 800); }
.card-bg-red-700 { background: color($red, 700); }
.card-bg-green-700 { background: color($green, 700); }
.card-bg-deep-purple-700 { background: color($deep-purple, 700); }

.block-bricks { background: color($color-primary-base, 100);
  .card-brick { position: relative;
    .card-image { margin-bottom: 8px;
      a { display: block; }
      img { margin-bottom: 32px; }
    } 
    .card-title { position: absolute; bottom: 16px; left: 16px; right: 16px; pointer-events: none; }
  }
  .photoswipe {
    &:after { color: rgba($color-text, .35); }
  }
}

.block-features-3 { background-color: color($grey, 200); background-image: url('../img/features-3-bg.jpg'); //background: color($grey, 300); 
  ul { margin-left: 0; margin-right: 0; }
  li { font-size: 18px; margin-bottom: 12px; padding-left: 48px; position: relative;
    &:before { font-size: 32px; position: absolute; left: 0; top: 8px; }
  }
  .card-title { display: flex; align-items: center; justify-content: center; }
  @include breakpoint($xs) { 
    ul { margin-left: 64px; margin-right: 64px; }
    li { padding-left: 64px; }
  }
}

.features-3-addition { margin-bottom: -56px; position: relative; z-index: 10;
  .card-title { min-height: 112px; }
}

.block-features-4 {
  .left { display: flex; align-items: center; justify-content: flex-end; background: color($brown, 700); color: $color-text-light; position: relative; overflow: hidden;
    &:before { position: absolute; top: 24px; left: 50%; color: $color-text-light; font-size: 128px; margin-left: -64px; opacity: 0.2; z-index: 1; }
  }
  .feature-item-left { margin: 104px 0 64px 0; /* max-width: 544px; */ font-size: 20px; position: relative; z-index: 2; }
  .right { background: color($orange, 400); position: relative; overflow: hidden;
    &:after { position: absolute; bottom: -32px; left: 50%; color: $color-text-light; font-size: 128px;  margin-left: -64px; opacity: 0.2; z-index: 1; }
  }
  .feature-item-right { margin: 64px 0 64px 0; /* max-width: 544px; */ position: relative; z-index: 2;
    p { font-size: 18px; }
  }
  @include breakpoint($xs) { 
    .feature-item-left, .feature-item-right { margin-left: 12px; margin-right: 12px; }
  }
  @include breakpoint(640px) { 
    .feature-item-left, .feature-item-right { max-width: 544px; margin-left: auto; margin-right: auto; }
  }
  @include breakpoint($sm) { 
    .feature-item-left { margin: 104px 48px 64px 12px;
      .text { margin-bottom: 48px; } 
    }
    .feature-item-right { margin: 104px 12px 64px 48px; }
  }
}

.block-features-5 { background-image: linear-gradient(to bottom, rgba(0,0,0,0.2) 0%,rgba(0,0,0,0.2) 100%), url('../img/features-5-bg.jpg'); min-height: 520px;
  background-color: color($grey, 400); background-position: left top, center center; background-size: auto, cover; color: $color-text-light;
  .feature-item-left { display: flex; font-size: 20px; align-items: center; }
  .feature-item-right {
    p { font-size: 18px; }
  }
}

.block-reviews {
  .card { margin-top: -120px; position: relative; overflow: hidden;
    &:after { position: absolute; top: 50%; right: -94px; margin-top: -96px; font-size: 192px; opacity: 0.1; z-index: 1; }
  }
  .card-item { position: relative; z-index: 2; }
  .slider { margin-left: 12px; margin-right: 12px; }

  .cycle-slideshow { overflow: hidden; }
  .slide { width: 100%; }
  .control { display: inline-block; margin: 12px 12px 0; border-radius: 50%; background: $color-bg-bar; width: 56px; height: 56px; text-align: center; padding-top: 10px; cursor: pointer;
    &:hover { background: $color-bg-hover; }
  }
  .avatar img { display: block; border-radius: 50%; width: 72px; height: 72px; margin: 0 auto; }
  .name { text-align: center; font-size: 18px; font-weight: 500; margin: 12px 0; }
  @include breakpoint($xs) { 
    .slider { margin-left: 24px; margin-right: 24px; }
  }
  @include breakpoint($sm) { 
    .slider { margin-left: 64px; margin-right: 64px; }
  }
}

.block-team { display: none;
  .block-title { color: $color-primary; }
  .avatar img { display: block; border-radius: 50%; width: 72px; height: 72px; margin: 0 auto; }
  .name { font-size: 18px; font-weight: 500; margin-top: 12px; }
  .description { max-width: 240px; margin: 24px auto 0 auto; }
  @include breakpoint($md) {
    & { display: block; }
  }
}

.block-call-now {
  h3 { margin-top: 0; } 
  .col-left { background: color($color-accent-base, 300);
    a { color: $color-text; }
    //.icon { background: $color-text; color: color($color-accent-base, 200); }
  }
  .col-right {
    h3 { color: $color-text-light; }
    //.icon { background: $color-text-light; color: $color-primary; }
  }
  //.icon { display: block; height: 72px; width: 72px; border-radius: 50%; padding: 12px; margin: 0 auto 32px;
  //  i { font-size: 48px; }
  //}
  .col-left-content, .col-right-content { margin: 64px 0; display: flex; flex-direction: column;  }
  .col-right-content {  margin-bottom: 112px; }
  .description, .phone { margin-bottom: 8px; }
  .phone { font-weight: 500; }
  @include breakpoint($xs) {
    .col-left-content { margin: 64px 48px; max-width: 544px; flex-direction: row; justify-content: space-between; align-items: center;
      //.icon { order: 2; }
      //.info-group { padding-right: 32px; flex: 1 1 auto; order: 1; } 
    }
    .col-right-content { margin: 64px 48px 112px; max-width: 544px; flex-direction: row; justify-content: space-between; align-items: center;
      //.info-group { padding-left: 32px; flex: 1 1 auto; }
      
    }
    //.icon { flex: 0 0 72px; margin: 0; }
    .description span { display: block; }
  }
  @include breakpoint(640px) {
    .col-left-content, .col-right-content { margin-left: auto; margin-right: auto; }
  }
  @include breakpoint($md) { 
    .col-left { display: flex; justify-content: flex-end; }
    .col-left-content { margin-left: 48px; margin-right: 48px; margin-bottom: 112px; width: 100%; }
    .col-right-content { margin-left: 48px; margin-right: 48px; }
  }
}

.block-showroom { background: #d9d0cb; padding-bottom: 580px;
  .block-title { color: $color-primary; }
  @include breakpoint($md) {
    padding-bottom: 240px;
  }
}

.block-contacts { position: relative; z-index: 2;
  .card { margin-top: -484px; margin-bottom: -64px; background: color($orange, 500); position: relative; overflow: hidden;
    &:before { position: absolute; top: 50%; left: 5%; color: $color-text-light; font-size: 192px; margin-top: -96px; opacity: 0.2; z-index: 1; }
  }
  .card-item { position: relative; z-index: 2; }
  .h3 { margin-right: 16px; }
  .contacts { font-size: 18px; margin-left: 16px;
    a { color: $color-text-dark; }
  }
  .header-working { color: #097737; display: flex; align-items: center; opacity: 0;
    &.open-now { opacity: 1; }
    .working-icon { width: 8px; height: 8px; border-radius: 50%; background: #097737; margin-right: 8px; }
  }
  @include breakpoint($md) {
    .card { margin-top: -156px; margin-bottom: -240px; }
  }
}

.block-map { background: color($grey, 600); position: relative; z-index: 1;
  #gmap { height: 320px; width: 100%; pointer-events: none; }
  @include breakpoint($md) {
    #gmap { pointer-events: auto; height: 640px; }
  }
}

#footer { background: color($grey, 700); color: $color-text-light; }

